import {useCallback, useEffect, useState} from 'react'
import {MobileAppDetail} from '../types/MobileAppDetail'
import {SubmissionResultType, SubmissionResult} from '../types/SubmissionResult'
import {strings} from '../localization/strings'
import {getPublisher} from '../types/Publisher'
import {MobileAppStatusName} from '../types/MobileAppStatusName'
import {getAppIdentifierPrefix} from '../types/DistributionMethod'
import {customDeepmerge} from '../helpers/CustomDeepMerge'
import useNetworkService from '../services/NetworkService.hooks'

type AppSubmissionResult = {
    isLoading: boolean
    loadingMessage: string
    initialFormData: Partial<MobileAppDetail>
    isAppUpdate: boolean
    previousAppVersion: string
    submissionResult: SubmissionResult | null
    fetchAppDetail: () => void
    handleSubmit: (finalFormData: Partial<MobileAppDetail>) => void
    handleUpdate: (finalFormData: Partial<MobileAppDetail>) => void
    handleRetry: () => void
}

export const useAppSubmission = (state: any): AppSubmissionResult => {
  const [loadingMessage, setLoadingMessage] = useState<string>(strings.app.loading)
  const [initialFormData, setInitialFormData] = useState<Partial<MobileAppDetail>>({})
  const [isAppUpdate, setIsAppUpdate] = useState<boolean>(false)
  const [previousAppVersion, setPreviousAppVersion] = useState<string>('')
  const [submissionResult, setSubmissionResult] = useState<SubmissionResult | null>(null)

  const {updateMobileApp, getMobileAppDetail, submitMobileApp, isLoading} = useNetworkService()

  const {result: texts} = strings.submission

  const fetchAppDetail = useCallback(async () => {
    // INITIAL RELEASE REQUEST
    if (!state || !state.id || !state.platform) {
      return
    }

    // NEW RELEASE REQUEST
    const {id: appId, platform} = state
    try {
      // Remove id from existing app detail
      const {id, ...appDetail} = await getMobileAppDetail(platform, appId)
      setInitialFormData(appDetail)
      setPreviousAppVersion(appDetail.version)
      setIsAppUpdate(true)
    } catch (error: any) {
      setSubmissionResult({
        type: SubmissionResultType.FetchAppDetail,
        status: 'error',
      })
    }
  }, [getMobileAppDetail, state])

  useEffect(() => {
    fetchAppDetail()
  }, [fetchAppDetail])

  // TODO: Remove 'packagingType' from the form data before submitting the app
  const handleSubmit = useCallback(async (finalFormData: Partial<MobileAppDetail>) => {
    window.scrollTo(0, 0)

    const submissionData = customDeepmerge(initialFormData, finalFormData) as MobileAppDetail
    setInitialFormData(submissionData)
    if (!isAppUpdate) {
      const appIdentifierPrefix = getAppIdentifierPrefix(submissionData.distributionMethod)
      submissionData.appIdentifier = `${appIdentifierPrefix}${submissionData.appIdentifier}`
    }
    submissionData.jiraIssue = {}
    submissionData.screenshots = []
    submissionData.status = {name: MobileAppStatusName.RequestReview}

    if (!submissionData.publisher) {
      const {distributionMethod, division, platform} = submissionData
      submissionData.publisher = getPublisher(distributionMethod, division, platform)
    }

    try {
      setLoadingMessage(strings.loadingMessage.submittingForm)
      const response = await submitMobileApp(submissionData)
      setSubmissionResult({
        type: SubmissionResultType.FormSubmission,
        status: 'success',
        response,
      })
    } catch (error: any) {
      setSubmissionResult({
        type: SubmissionResultType.FormSubmission,
        status: 'error',
      })
    }
  }, [initialFormData, isAppUpdate, submitMobileApp])

  // TODO: Remove 'packagingType' from the form data before updating the app
  const handleUpdate = useCallback(async (finalFormData: Partial<MobileAppDetail>) => {
    window.scrollTo(0, 0)

    if (!state || !state.id || !state.platform) {
      return
    }
    const {id: appId, platform} = state
    const {
      version,
      distributionMethod,
      platform: _,
      appIdentifier,
      division,
      ...restFormData
    } = finalFormData
    try {
      const updateResponse = await updateMobileApp(appId, platform, restFormData)
      setSubmissionResult({
        type: SubmissionResultType.UpdateSubmission,
        status: 'success',
        response: {
          message: texts.updateSuccessMessage,
          jiraTicketLink: updateResponse.mobileApp.jiraIssue.url ?? '',
        },
      })
    } catch (error: any) {
      // TODO: Handle error response for status code 422 (No changes detected)
      setSubmissionResult({
        type: SubmissionResultType.UpdateSubmission,
        status: 'error',
      })
    }
  }, [state, updateMobileApp, texts])

  const handleRetry = useCallback(() => {
    setSubmissionResult(null)
    if (submissionResult?.type === SubmissionResultType.FetchAppDetail) {
      fetchAppDetail()
    }
  }, [fetchAppDetail, submissionResult])

  return {
    isLoading,
    loadingMessage,
    initialFormData,
    isAppUpdate,
    previousAppVersion,
    submissionResult,
    fetchAppDetail,
    handleSubmit,
    handleUpdate,
    handleRetry,
  }
}
